<template>
  <div
    ref="observeEntity"
    class="o-homepage-offer"
    :class="bannerPosition === 'left' ? 'banner-left' : ''"
  >
    <ServerRender
      is="Heading"
      level="2"
      size="5xl"
    >
      {{ title }}
    </ServerRender>
    <MCarousel>
      <template v-if="loading">
        <LazyAProductCardSkeleton
          v-for="n in 6"
          :key="n"
          size="small"
          variant="carousel"
        />
      </template>
      <template v-else>
        <MProductCarouselItem
          v-for="product in products"
          :key="product.code"
          :value="product"
          variant="special-offer"
          :is-favorite="isProductFavorite(product.id)"
          :availability="productAvailability[product.id]"
          @toggle:favorite="toggleFavorite(product.id)"
          @set:detail-current-product="routerGo(product.code)"
        />
      </template>
    </MCarousel>
    <div class="o-homepage-offer__popular">
      <template v-if="withIsClosed">
        <PopularCategory
          v-for="(category, i) in popularCategories"
          :key="`${category?.section?.path?.path ?? category.title}-${i}`"
          :item="category"
          img-from-public
          with-pixel-ratio
          with-webp
        />
      </template>
      <template v-else>
        <ServerRender
          is="PopularCategory"
          v-for="(category, i) in popularCategories"
          :key="`${category?.section?.path?.path ?? category.title}-${i}`"
          :item="category"
          img-from-public
          with-pixel-ratio
          with-webp
        />
      </template>
    </div>

    <div
      v-if="bannerSrc && mobileBannerSrc"
      class="o-homepage-offer__image"
    >
      <NuxtLink
        :href="getUrl(bannerLink, !bannerLink.includes('.html'))"
        no-prefetch
      >
        <AImage
          :src="bannerSrc"
          :mobile-src="mobileBannerSrc"
          width="320"
          height="auto"
          with-retina
          with-webp
          :provider="fromPublic ? 'public' : 'cdn'"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ServerRender from '@/components/helpers/ServerRender'
import PopularCategory from '@/components/nuxt/global/PopularCategory/PopularCategory.vue'

import { defineAsyncComponent, defineComponent, ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useNuxtApp } from '#app'
import { useFavoritesStore } from '@/stores/favorites'
import { useNavigate } from '@/composables/useNavigate'
import { useProducts } from '@/stores/products'

import type { PropType, Ref } from 'vue'
import type { Product } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
import type { Availability } from '@/modules/nuxt-api/models/Product'
import type { CatalogSection } from '@/modules/nuxt-api/models/CatalogSection'

import AImage from '@/components/atoms/Image/AImage.vue'

import MCarousel from '@/components/molecules/Carousel/MCarousel.vue'
import MProductCarouselItem from '@/components/molecules/ProductCarouselItem/MProductCarouselItem.vue'

const LazyAProductCardSkeleton = defineAsyncComponent(() => /* @vite-ignore */ import('@/components/atoms/ProductCardSkeleton/AProductCardSkeleton.vue'))

type OfferBannerPosition = 'left' | 'right'

defineComponent({ name: 'OHomepageOffer' })

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  products: {
    type: Array as PropType<Product.AsObject[]>,
    default: () => []
  },
  popularCategories: {
    type: Array as PropType<CatalogSection[]>,
    default: () => []
  },
  bannerPosition: {
    type: String as PropType<OfferBannerPosition>,
    default: 'right'
  },
  bannerSrc: {
    type: String,
    default: ''
  },
  mobileBannerSrc: {
    type: String,
    default: ''
  },
  bannerLink: {
    type: String,
    default: ''
  },
  fromPublic: Boolean,
  loading: Boolean
})

const nuxtApp = useNuxtApp()
const route = useRoute()
const { getUrl } = useNavigate()

// Favorites
const { toggleProductFavorite, isProductFavorite } = useFavoritesStore()

const routerGo = (code: string): void => {
  const title = 'Переход на бест. продукт со страницы ' + route.path
  const url = `products/${code}.html`

  if (nuxtApp.$clicky) {
    nuxtApp.$clicky.log(url, title)
  }
}

const productAvailability: Ref<Record<number, Availability | undefined>> = ref({})
const withIsClosed = computed(() => props.popularCategories.some(item => item.section?.isClosed))

const getAvailability = async () => {
  const idsList = props.products?.map?.(item => item.id)
  productAvailability.value = await useProducts().getProductAvailability(idsList)
}

function toggleFavorite (productId: Product.AsObject['id']) {
  toggleProductFavorite(productId)
}

onMounted(() => {
  if (nuxtApp.payload.serverRendered) {
    getAvailability()
  }
})

watch(() => props.products, () => {
  getAvailability()
})
</script>

<style lang="postcss">
.o-homepage-offer {
  position: relative;
  display: grid;
  grid-template-columns: auto 20rem;
  align-items: start;
  margin-top: var(--spacer-8xl);
  column-gap: var(--spacer-3xl);

  & > .heading {
    grid-column: 1;
    margin-bottom: var(--spacer-2xl);
  }

  & > .carousel {
    grid-column: 1;
    width: calc(100% - 20rem - var(--spacer-3xl));
    min-height: 19.75rem;

    .carousel__list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      & > :nth-child(n + 6) {
        display: none;
      }
    }

    & > .icon-button {
      display: none;
    }

    @media (--screen-xs) {
      .carousel__list {
        display: flex;

        & > :nth-child(n + 1) {
          display: flex;
        }
      }
    }

    @media (--screen-lg) and (--screen-sm) {
      .carousel__list {
        grid-template-columns: repeat(4, 1fr);

        & > :nth-child(n + 5) {
          display: none;
        }
      }
    }
  }

  & .m-product-carousel-item .heading {
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & .m-product-carousel-item .image img {
    height: 9.75rem;
  }

  & .m-product-carousel-item__experts {
    display: none;
  }

  &.banner-left {
    grid-template-columns: 20rem auto;
  }
}

.o-homepage-offer__popular {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column: 1;
  gap: var(--spacer-xs);
  margin-top: var(--spacer-4xl);

  & > .popular-category {
    width: 100%;
  }
}

.o-homepage-offer__image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  & a > .image {
    flex: 1;
    height: 100%;
  }

  & a > .image img {
    object-fit: cover;
    width: 20rem;
    height: 100%;
    border-radius: var(--border-radius-xl);
    object-position: top;
  }
}

.banner-left {
  & > .heading,
  & > .carousel,
  & > .o-homepage-offer__popular {
    grid-column: 2;
  }

  & > .o-homepage-offer__image {
    right: auto;
    left: 0;
    grid-column: 1;
  }
}

@media (--screen-lg) {
  .o-homepage-offer .m-product-carousel-item {
    height: 19.75rem;
  }

  .o-homepage-offer__popular > .popular-category:nth-child(n + 6) {
    display: none;
  }
}

@media (--screen-lg) and (--screen-sm) {
  .o-homepage-offer__popular {
    grid-template-columns: repeat(4, 1fr);

    & > .popular-category:nth-child(n + 5) {
      display: none;
    }
  }
}

@media (--screen-xs) {
  .o-homepage-offer {
    margin-top: var(--spacer-4xl);
  }

  .o-homepage-offer,
  .o-homepage-offer.banner-left {
    grid-template-columns: 100%;

    & > * {
      grid-column: 1;
    }

    & > .heading {
      margin-bottom: var(--spacer-base);

      @mixin text-2xl;
    }

    & .carousel {
      width: auto;
      min-height: 21rem;

      &__list {
        gap: var(--spacer-3xs);

        & > div {
          height: 100%;
        }

        & > .m-product-carousel-item {
          overflow: hidden;
          width: 8.5rem;
          max-width: 8.5rem;

          & .m-product-carousel-item__rate {
            margin-bottom: var(--spacer-xs);
          }
        }
      }

      & .m-product-carousel-item__cart {
        display: flex;
        margin-top: auto;
      }
    }
  }

  .o-homepage-offer__popular {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacer-3xs);
    margin-top: var(--spacer-lg);

    & > .popular-category:nth-child(n + 7) {
      display: none;
    }
  }

  .o-homepage-offer__image {
    position: relative;
    grid-row: 3;
    width: 100%;
    height: auto;
    margin-top: var(--spacer-3xl);
    aspect-ratio: 991 / 344;

    & a > .image img {
      width: 100%;
      border-radius: var(--border-radius-base);
    }
  }
}
</style>
